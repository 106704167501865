import {FormState} from './FollowOnOrderFormReducer'
import {CreateWorkOrderRequest, Task} from '../../service/LonghurstConnectorServiceHook'
import {getCurrentDateTime} from '../../util/DateTime'
import moment from 'moment'

export const buildWorkOrderRequest = (formState: FormState): CreateWorkOrderRequest => {
    const tasks: Array<Task> = formState.sors.map((sor) => {
        return {
            SOR: sor.code,
            Quantity: sor.quantity,
        }
    })

    return {
        LGOrderRef: formState.existingOrderRef,
        ShortDescription: formState.shortDescription,
        Description: formState.description,
        OrderCreatedDate: moment(formState.existingOrderRaisedDate).toISOString(),
        OrderRecordCreated: '',
        Priority: formState.priority,
        ExpectedCompletionDate: getCurrentDateTime().toISOString(), //will be overridden on server anyway but is required
        Location: formState.location,
        Property: formState.propertyUprn,
        Contract: formState.contract,
        ContactTitle: formState.contactTitle,
        ContactFirstName: formState.contactFirstName,
        ContactLastName: formState.contactLastName,
        ContactMobilePhone: formState.contactPhone,
        ContactHomePhone: null,
        ContactWorkPhone: null,
        ContactPreferredNumber: 'Mobile',
        AccessDetails: formState.accessDetails,
        NeedTypes: formState.needTypes,
        Disabilities: formState.disabilities,
        Tasks: tasks,
        ReportedBy: formState.reportedBy,
        Recall: false
    }
}

export const isPageDebugEnabled = ():boolean => {
    let param = new URL(window.location.toString()).searchParams.get('debug')
    return param === null ? false : param?.toLowerCase() === 'true'
}
export const isLocalValidationDisabled = ():boolean => {
    let param = new URL(window.location.toString()).searchParams.get('disableLocalValidation')
    return param === null ? false : param?.toLowerCase() === 'true'
}
export const getParamSimulateStatusCode = () => new URL(window.location.toString()).searchParams.get('simulateStatusCode')
export const getParamSimulateStatusCodeText = () => new URL(window.location.toString()).searchParams.get('simulateStatusCodeText')
export const getParamSimulateResponseBody = () => new URL(window.location.toString()).searchParams.get('simulateResponseBody')

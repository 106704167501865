import React, {useState} from 'react'
import {FloatingLabel, Form} from 'react-bootstrap'
import {AsyncTypeahead, Highlighter} from 'react-bootstrap-typeahead'

export interface Option {
    id: string,
    description: string
}

type Props = {
    id: string
    options: Option[]
    onChange: (option: Option) => void
    onSearch: (query: string) => void
    controlId: string
    label: string
}

export const IWAsyncTypeahead = (props: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)

    return (
        <>
            <AsyncTypeahead
                id={props.id}
                labelKey={(option) => `(${(option as Option).id}) ${(option as Option).description}`}
                options={props.options}
                isLoading={isLoading}
                onChange={(option) => props.onChange(option[0] as Option)}
                onSearch={(query) => {
                    setIsLoading(true)
                    props.onSearch(query)
                    setIsLoading(false)
                }}
                renderMenuItemChildren={(res, props, index) => {
                    const option = res as Option
                    return (
                        <div title={option.description}>
                            <span>({option.id})</span>
                            <span>
                                <Highlighter search={props.text}>
                                    {option.description}
                                </Highlighter>
                            </span>
                        </div>
                    )
                }}
                renderInput={({inputRef, referenceElementRef, ...inputProps}) => (
                    <FloatingLabel controlId={props.controlId} label={props.label}>
                        {/* @ts-ignore */}
                        <Form.Control
                            {...inputProps}
                            type={'text'}
                            data-testid={`iw-typeahead-field-${props.controlId}`}
                            ref={(input: HTMLInputElement) => {
                                inputRef(input)
                                referenceElementRef(input)
                            }}
                        />
                    </FloatingLabel>
                )}
            />
        </>
    )
}

import fetch_retry, {RequestInitWithRetry} from 'fetch-retry'
import {UseApiTokenService} from './ObtainApiTokenServiceHook'

export type RequestOptions = RequestInitWithRetry & { getToken: UseApiTokenService }

export const request = async (url: URL, options: RequestOptions): Promise<Response> => {
    const token = await options.getToken()
    let attempt = 1
    let headers = createHeaders(token, attempt)

    const defaultOptions: RequestInitWithRetry = {
        headers: headers,
        retries: 1,
        retryDelay: 0,
        retryOn: async (attempt, error, response) => {
            if (response?.status === 401) {
                let previousAttempt = Number.parseInt(headers.get('X-Attempt') ?? '0')
                headers.set('X-Attempt', (++previousAttempt).toString())
                headers.set('Authorization', 'Bearer ' + await options.getToken())
                console.info('Unauthorised request going to get a new token and retry')
                return true
            }
            return false
        }
    }
    return fetch(url.toString(), {...defaultOptions, ...options})
}

const createHeaders = (accessToken: string, attempt: number) => {
    const headers = new Headers()
    const bearer = `Bearer ${accessToken}`

    headers.append('Authorization', bearer)
    headers.append('Content-Type', 'application/json')
    headers.append('x-attempt', attempt.toString())
    return headers
}

const fetch = fetch_retry(global.fetch, {
    retries: 2,
    retryDelay: 800
})

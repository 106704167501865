import React from 'react'
import './App.css'
import {PageLayout} from './components/layout/PaygeLayout'
import {AuthenticatedTemplate, UnauthenticatedTemplate} from '@azure/msal-react'
import {CreateLonghurstOrderForm} from './components/create-follow-on-order/CreateLonghurstOrderForm'
import {Container} from 'react-bootstrap'

function App() {
    return (
        <div className='App'>
        <PageLayout>
            <AuthenticatedTemplate>
                <Container>
                    <CreateLonghurstOrderForm/>
                </Container>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <p data-testid='please-sign-in-text'>Please sign in by clicking the button at the top</p>
            </UnauthenticatedTemplate>
        </PageLayout>
        </div>
    )
}

export default App

import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import {useIsAuthenticated} from '@azure/msal-react'
import {SignInButton} from 'components/header/SignInButton'
import {SignOutButton} from 'components/header/SignOutButton'
import {Col, Container, NavbarBrand} from 'react-bootstrap'
import logo from '../header/iw-logo-white-with-tag.svg'

type Props = {
    children: React.ReactNode
}

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const PageLayout = (props: Props) => {
    const isAuthenticated = useIsAuthenticated()

    return (
        <>
            <Navbar bg='primary' variant='light'>
                <Container>
                    <NavbarBrand>
                        <img
                            src={logo}
                            height='70px'
                            className='d-inline-block align-top'
                            alt='React Bootstrap logo'
                        />
                    </NavbarBrand>
                    {isAuthenticated ? <SignOutButton/> : <SignInButton/>}
                </Container>
            </Navbar>
            <Container>
                <Col>
                    {props.children}
                </Col>
            </Container>

        </>
    )
}

import {getAuthConfig} from '../config/EnvConfig'
import {useApiTokenService} from './ObtainApiTokenServiceHook'
import {request, RequestOptions} from './Request'

export type CreateWorkOrderRequest = {
    LGOrderRef: string
    ShortDescription: string
    Description: string
    OrderCreatedDate: string
    OrderRecordCreated: string
    Priority: string
    ReportedBy: string
    ExpectedCompletionDate: string
    Location: string
    Property: string
    Contract: string
    ContactTitle: string
    ContactFirstName: string
    ContactLastName: string
    ContactMobilePhone: string
    ContactHomePhone: string | null
    ContactWorkPhone: string | null
    ContactPreferredNumber: string
    AccessDetails: string,
    NeedTypes: Array<string>,
    Disabilities: Array<string>,
    Tasks: Array<Task>,
    Recall: boolean
}

export type Task = {
    SOR: string
    Quantity: string
    TaskRef?: string
    ExternalReference?: string
    Recharge?: string
    Location?: string
}

export type CreateWorkOrder = (workOrderRequest: CreateWorkOrderRequest) => Promise<Response>
export type GetWorkOrder = (orderReference: string) => Promise<Response>
export type FindSors = (query: string) => Promise<Response>

export type LonghurstService = {
    create: CreateWorkOrder
    get: GetWorkOrder
    findSors: FindSors
}

/**
 * Hook to provide access to the longhurst connector API
 */
export const useLonghurstService = (): LonghurstService => {
    const obtainLonghurstApiTokenSilent = useApiTokenService(false)
    return {
        async create(workOrderRequest: CreateWorkOrderRequest): Promise<Response> {
            const options: RequestOptions = {
                method: 'POST',
                body: JSON.stringify(workOrderRequest),
                getToken: obtainLonghurstApiTokenSilent
            }
            return request(new URL(`${getAuthConfig().longhurstConnectorBaseUrl}/v2/api/async/follow-on-work-order`), options)
        },
        async get(orderReference: string): Promise<Response> {
            const options: RequestOptions = {
                method: 'GET',
                getToken: obtainLonghurstApiTokenSilent
            }
            return request(new URL(`${getAuthConfig().longhurstConnectorBaseUrl}/v2/api/orders?ref=${orderReference}`), options)
        },
        async findSors(query: string): Promise<Response> {
            const options: RequestOptions = {
                method: 'GET',
                getToken: obtainLonghurstApiTokenSilent
            }
            return request(new URL(`${getAuthConfig().longhurstConnectorBaseUrl}/v2/api/sors?description=${query}`), options)
        }
    }
}

import {Reducer} from 'react'
import produce from 'immer'
import {getCurrentDateTime} from '../../util/DateTime'

type FormStateStrings = {
    existingOrderRef: string
    shortDescription: string
    description: string
    priority: string
    contract: string
    location: string
    propertyUprn: string
    contactTitle: string
    contactFirstName: string
    contactLastName: string
    contactPhone: string
    accessDetails: string
    existingOrderRaisedDate: string
    reportedBy: string
}


type FormStateStringArrays = {
    needTypes: Array<string>
    disabilities: Array<string>
}

type FormStateSors = {
    sors: Array<Sor>
}

export type FormState = FormStateStrings
    & FormStateStringArrays
    & FormStateSors

type Sor = {
    rowId: number,
    code: string,
    quantity: string,
}
/**
 * To validate an object as formState
 * @param object
 */
const isFormState = (object: any): object is FormState => {
    let obj = object as FormState
    return obj.existingOrderRef !== undefined
        && obj.shortDescription !== undefined
        && obj.description !== undefined
        && obj.priority !== undefined
        && obj.contract !== undefined
        && obj.location !== undefined
        && obj.propertyUprn !== undefined
        && obj.contactTitle !== undefined
        && obj.contactFirstName !== undefined
        && obj.contactLastName !== undefined
        && obj.contactPhone !== undefined
        && obj.accessDetails !== undefined
        && obj.existingOrderRaisedDate !== undefined
        && obj.reportedBy !== undefined
}

export const anEmptyForm: () => FormState = () => ({
    existingOrderRef: '',
    shortDescription: '',
    description: '',
    priority: '',
    contract: '',
    location: '',
    propertyUprn: '',
    contactTitle: '',
    contactFirstName: '',
    contactLastName: '',
    contactPhone: '',
    accessDetails: '',
    needTypes: [],
    disabilities: [],
    existingOrderRaisedDate: '',
    sors: [{
        rowId: getCurrentDateTime().valueOf(),
        code: '',
        quantity: '1',
    }],
    reportedBy: '',
})

export const SESSION_STORAGE_KEY = 'create-longhurst-order-form-state'

const getStoredSession = (): FormState | undefined => {
    const storedStateAsString = sessionStorage.getItem(SESSION_STORAGE_KEY)
    if (storedStateAsString != null) {
        try {
            const raw = JSON.parse(storedStateAsString)
            if (raw !== undefined && isFormState(raw)) {
                let restoredForm = raw as FormState
                console.info(`restoring session: ${JSON.stringify(restoredForm)}`)
                return restoredForm
            } else {
                console.warn(`session-state found is invalid: ${JSON.stringify(raw)} `)
            }
        } catch (e) {
            console.warn('state was invalid json giving up')
        }
    }
}


export type FormAction =
    | { type: 'reset-form', newFormState: FormState }
    | { type: 'restore-session' }
    | { type: 'update', field: keyof FormStateStrings, value: string }
    | { type: 'update-string-array', field: keyof FormStateStringArrays, index: number, value: string }
    | { type: 'add-string-entry', field: keyof FormStateStringArrays }
    | { type: 'delete-from-array', field: keyof FormStateStringArrays | 'sors', index: number }
    | { type: 'update-sor-quantity', quantity: string, index: number }
    | { type: 'update-sor-code', code: string, index: number }
    | { type: 'add-sor' }

function deepCopy(storedSession: FormStateStrings & FormStateStringArrays & FormStateSors) {
    return produce(storedSession, () => {
    })
}

/**
 * Functions to perform on the state
 * @param oldState
 * @param action
 */
export const followOnFormReducer: Reducer<FormState, FormAction> = (oldState: FormState, action: FormAction) => {
    let state = oldState
    switch (action.type) {
        case 'reset-form':
            state = deepCopy(action.newFormState)
            break
        case 'restore-session':
            const storedSession = getStoredSession()
            if (storedSession) {
                state = deepCopy(storedSession)
            }
            break
        case 'update':
            state = produce(oldState, draft => {
                draft[action.field] = action.value
            })
            break
        case 'update-string-array': {
            state = produce(oldState, draft => {
                draft[action.field].splice(action.index, 1, action.value)
            })
            break
        }
        case 'add-string-entry': {
            state = produce(oldState, draft => {
                draft[action.field].push('')
            })
            break
        }
        case 'delete-from-array': {
            state = produce(oldState, draft => {
                draft[action.field].splice(action.index, 1)
            })
            break
        }
        case 'update-sor-code': {
            state = produce(oldState, draft => {
                draft.sors[action.index].code = action.code
            })
            break
        }
        case 'update-sor-quantity': {
            state = produce(oldState, draft => {
                draft.sors[action.index].quantity = action.quantity
            })
            break
        }
        case 'add-sor': {
            state = produce(oldState, draft => {
                draft.sors.push({rowId: getCurrentDateTime().valueOf(), code: '', quantity: '1'})
            })
            break
        }
        default: {
            console.error(`action not recognised ${JSON.stringify(action)}`)
        }
    }
    sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(state))
    return state
}



import {Modal} from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import React from 'react'

type Props = {
    show: boolean,
    message: string,
    onClose: () => void,
    name: string,
}

/**
 * Modal with an OK button
 * @param props
 * @constructor
 */
export const OkModal = (props: Props) => {

    function onHide() {
        props.onClose()
    }

    return (
        <Modal data-testid={`${props.name}-modal`}
               show={props.show}
               size='lg'
               centered={true}
               animation={true}>
            <Modal.Header>
                <Modal.Title>{props.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <pre>
                    {props.message}
                </pre>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    data-testid={`close-${props.name}-button`}
                    variant='primary'
                    onClick={onHide}
                    aria-label={`close ${props.name} dialog button button`}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

import {CloseButton, Col, Container, Row} from 'react-bootstrap'
import React from 'react'
import Button from 'react-bootstrap/Button'
import {IWTextField} from './IWTextField'

type Props = {
    /**
     * Title of the section of the form
     */
    title: string
    /**
     * Name of the input fields, eg 'name' will result in fields called 'name-0', 'name-1' etc
     */
    name: string,
    /**
     * Label to be used on the input
     */
    label: string,
    /**
     * HTML input type of the text input eg 'text', 'number', 'datetime-local'
     */
    type: string,
    /**
     * The value of the inputs
     */
    values: Array<string>,
    /**
     * Label to use on the add button
     */
    addButtonLabel: string
    /**
     * a function to update the array when it is changed
     * @param newValue
     */
    onChange: (newValue: string, index: number) => void,

    /**
     * a function to update the array when it is changed
     * @param newValue
     */
    onDelete: (index: number) => void,

    /**
     * a function to update the array when a new entry is added
     * @param newValue
     */
    onCreate: () => void,

    readOnly?: boolean
}

/**
 * A dynamic array of text input fields which can grow or shrink. Please note these are only expected to be used
 * on the create-follow-on-order page due to them having layout content.
 * @param props
 */
export const IWDynamicListTextField = (props: Props) => {
    return (<>
        <Container>
            <h5>{props.title}</h5>
            {
                props.values.map((value, idx) => {
                    return <Row key={props.name + idx}>
                        <Col md='auto'>
                            <IWTextField name={`${props.name}-${idx}`}
                                         value={value}
                                         label={`${props.label}`}
                                         readOnly={props.readOnly}
                                         onChange={(newValue): void => props.onChange(newValue, idx)}/>
                        </Col>
                        {!props.readOnly &&
                        <Col>
                            <CloseButton onClick={() => props.onDelete(idx)}/>
                        </Col>
                        }
                    </Row>
                })
            }
            {!props.readOnly &&
            <Row>
                <Col>
                    <Button variant={'outline-primary'}
                            onClick={() => props.onCreate()}>{props.addButtonLabel}</Button>
                </Col>
                <Col></Col>
            </Row>
            }
        </Container>
    </>)

}

IWDynamicListTextField.defaultProps = {
    type: 'text',
    addButtonLabel: 'Add'
}

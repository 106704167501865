import {Configuration} from '@azure/msal-browser/dist/config/Configuration'
import {
    localMsalConfig,
    LONGHURST_CONNECTOR_REQUEST_SCOPES_PRODUCTION,
    LONGHURST_CONNECTOR_REQUEST_SCOPES_STAGING,
    LONGHURST_CONNECTOR_REQUEST_SCOPES_TEST,
    productionMsalConfig,
    stagingMsalConfig,
    testMsalConfig
} from './AuthConstants'
import {err, MaybeError, value} from '../util/MaybeError'

export interface GetEnvConfig {
    (hostname: string): MaybeError<EnvironmentConfiguration, Error>
}

export type EnvironmentConfiguration = {
    msalConfig: Configuration,
    longhurstConnectorBaseUrl: string,
    longhurstScopes: Array<string>,
}

const TEST_LONGHURST_CONNECTOR_HOST = 'ghyston-roc-longhurst-test.azurewebsites.net'
const PROD_LONGHURST_CONNECTOR_HOST = 'roc-longhurst.ianwilliams.co.uk'
const STAGING_LONGHURST_CONNECTOR_HOST = 'ghyston-roc-longhurst-staging.azurewebsites.net'
/**
 * function to obtain environment specific configuration for the security based on the hostname.
 * @param hostname
 */
export const getAuthConfigFromHostname = (hostname: string): MaybeError<EnvironmentConfiguration, Error> => {
    switch (hostname) {
        case 'portal.ianwilliams.co.uk' :
            return value({
                msalConfig: productionMsalConfig,
                longhurstConnectorBaseUrl: `https://${PROD_LONGHURST_CONNECTOR_HOST}`,
                longhurstScopes: LONGHURST_CONNECTOR_REQUEST_SCOPES_PRODUCTION,
            })
        case 'delightful-grass-06ab28703-staging.westeurope.2.azurestaticapps.net' :
            return value({
                msalConfig: stagingMsalConfig,
                longhurstScope: '',
                longhurstConnectorBaseUrl: `https://${STAGING_LONGHURST_CONNECTOR_HOST}`,
                longhurstScopes: LONGHURST_CONNECTOR_REQUEST_SCOPES_STAGING,
            })
        case 'localhost' :
            return value({
                msalConfig: localMsalConfig,
                longhurstScope: '',
                longhurstConnectorBaseUrl: 'http://localhost:7011',
                longhurstScopes: LONGHURST_CONNECTOR_REQUEST_SCOPES_TEST,
            })
        default:
            // Support preview environments
            if (hostname.startsWith('delightful-grass-06ab28703-')
                && hostname.endsWith('.westeurope.2.azurestaticapps.net')) {
                // until we have a sign on working with IW AzureAD I am going to use my personal AD
                return value({
                        msalConfig: {
                            ...testMsalConfig,
                            auth: {
                                ...testMsalConfig.auth,
                                redirectUri: 'https://' + hostname // dynamic host name logic we can use for all preview domains.
                            }
                        },
                        longhurstConnectorBaseUrl: `https://${TEST_LONGHURST_CONNECTOR_HOST}`,
                        longhurstScopes: LONGHURST_CONNECTOR_REQUEST_SCOPES_TEST,
                    }
                )
            } else {
                return err(Error(`No Auth Config found for hostname '${hostname}'`))
            }
    }
}

export const getAuthConfig = (): EnvironmentConfiguration => {
    const config = getAuthConfigFromHostname(window.location.hostname)
    if (config.isOk()) {
        return config.value
    } else {
        throw config.error
    }
}

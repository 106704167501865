import React from 'react'
import {useMsal} from '@azure/msal-react'
import Button from 'react-bootstrap/Button'
import {IPublicClientApplication} from '@azure/msal-browser/dist/app/IPublicClientApplication'

function handleLogout(instance: IPublicClientApplication) {
    instance.logoutRedirect().catch(e => {
        console.error(e)
    })
}

/**
 * Renders a button which, when selected, will redirect the page to the logout prompt
 */
export const SignOutButton = () => {
    const {instance} = useMsal()
    return (
        <>
            <span></span>
            <Button size='lg'
                    onClick={() => handleLogout(instance)}>
                <small>{instance.getAllAccounts()[0].name}</small>
                <br/>
                Sign out
            </Button>
        </>
    )
}

import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {MsalProvider} from '@azure/msal-react'
import {PublicClientApplication} from '@azure/msal-browser'
import {getAuthConfigFromHostname} from './config/EnvConfig'
import 'theme.scss'
import {ApiAuthorisationContextProvider} from './components/ApiAuthorisationContext'

// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
    console.trace = () => {}
    console.debug = () => {}
    console.log = () => {}
    // console.error = () => {} logging errors in production might be helpful
}


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)
let envConfig = getAuthConfigFromHostname(window.location.hostname)
if (envConfig.isErr()) {
    root.render('AuthConfig Error: ' + envConfig.error.message)
} else {
    const msalInstance = new PublicClientApplication(envConfig.value.msalConfig)
    root.render(
        <React.StrictMode>
            <MsalProvider instance={msalInstance}>
                <ApiAuthorisationContextProvider>
                    <App/>
                </ApiAuthorisationContextProvider>
            </MsalProvider>
        </React.StrictMode>
    )
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)

import {FloatingLabel, Form} from 'react-bootstrap'
import React from 'react'

type Props = {
    name: string,
    label: string,
    type: string,
    value: string,
    onChange: (newValue: string) => void,
    readOnly? : boolean
}

/**
 * Text field for use on the {@link CreateLonghurstOrderForm}
 * @param props
 */
export const IWTextField = (props: Props) => {
    if(props.name === 'test') {
        console.log(`props ${JSON.stringify(props)}`)
    }
    return (<>
        <FloatingLabel controlId={props.name} label={props.label}>
            <Form.Control type={props.type} // defaults to 'text' see
                          value={props.value}
                          data-testid={`iw-text-field-${props.name}`}
                          readOnly={props.readOnly}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                              props.onChange(e.currentTarget.value)
                          }}/>
        </FloatingLabel>
    </>)

}

IWTextField.defaultProps = {
    type: 'text'
}

import {FloatingLabel, Form} from 'react-bootstrap'
import React from 'react'

type Props = {
    name: string,
    label: string,
    value: string,
    onChange: (newValue: string) => void,
    children: React.ReactNode, // options
    className?: string, //standard css classes
}


/**
 * Text field for use on the {@link CreateLonghurstOrderForm}
 * will fire the onChange event when the input element looses focus.
 * @param props
 */
export const IWSelectField = (props: Props) => {

    return (<FloatingLabel controlId={props.name} label={props.label}>
        <Form.Select className={props.className} value={props.value}
                     onChange={(e) => props.onChange(e.currentTarget.value)}>
            {props.children}
        </Form.Select>
    </FloatingLabel>)

}

import React, {useState} from 'react'
import {OkModal} from './common/OkModal'


export interface ApiAuthorisationCtx {
    longhurstAuthorised: boolean,
    setLonghurstAuthorised: (longhurstAuthorised: boolean) => void,
}


export const ApiAuthorisationContext = React.createContext({
    longhurstAuthorised: false,
    setLonghurstAuthorised(_: boolean) {
    },
})

/**
 * A component which no longer has a real purpose as we are using hooks and msal to maintain the actual state.
 *
 * I suggest this should probably become a Component which requests authorisation to the longhurst API and displays
 * errors accordingly. that said now that we are using redirects for the authorisation the error messaging aspect
 * isn't really required.
 *
 * @param props
 * @constructor
 */
export const ApiAuthorisationContextProvider = (props: {
    children: React.ReactNode
}) => {
    const [longhurstAuthorised, setLonghurstAuthorised] = useState<boolean>(false)
    const [showError, setShowError] = useState(false)
    const [authErrorMessage, setAuthErrorMessage] = useState<any>()


    const displayError = async (e: string) => {
        console.debug('Error attempting to grant authorisation', e)
        setAuthErrorMessage(e)
        setShowError(true)
    }

    const setLonghurstAuthorisedAndDisplayErrors = (authorised: boolean) => {
        setLonghurstAuthorised(authorised)
        if(!authorised) {
            displayError('Unable to authorise Longhurst')
                .catch((err) => { console.error(`Unable to display error message dialog box: ${err}`) })
        }
    }

    return <ApiAuthorisationContext.Provider value={{
        longhurstAuthorised,
        setLonghurstAuthorised: setLonghurstAuthorisedAndDisplayErrors,
    }}>
        <OkModal
            name={'Error Obtaining Authorisation'}
            show={showError}
            message={authErrorMessage}
            onClose={() => {
                setShowError(false)
            }}/>
        {props.children}
    </ApiAuthorisationContext.Provider>
}

import {IPublicClientApplication} from '@azure/msal-browser/dist/app/IPublicClientApplication'
import {AccountInfo, SilentRequest} from '@azure/msal-browser'
import {getAuthConfig} from '../config/EnvConfig'
import {RedirectRequest} from '@azure/msal-browser/dist/request/RedirectRequest'
import {useMsal} from '@azure/msal-react'

/**
 * If the user hasn't already approved that the portal can access the API in question then we need to get permission.
 * @param instance msal
 * @param accounts all accounts for the msal user...
 * @param apiScopes these will typically be the scopes for APIs
 */
const obtainApiTokenRedirect = (instance: IPublicClientApplication, accounts: AccountInfo[], apiScopes: string[]) => {
    console.log('running obtainApiTokenRedirect')
    const redirectRequest: RedirectRequest = {
        scopes: apiScopes,
        account: accounts[0], // potential bug, could be an issue if multiple accounts are signed in.
    }
    return instance.acquireTokenRedirect(redirectRequest)
        .then(() => Promise.resolve('acquired following obtainApiTokenRedirect'))
        .catch((e) => {
            console.log(`obtainApiTokenRedirect got error response ${e}`)
            return Promise.reject(`unable to obtain access token ${e}`)
        })
}

const obtainLonghurstApiToken = async (instance: IPublicClientApplication, accounts: AccountInfo[], apiScopes: string[]): Promise<string> => {
    return obtainLonghurstApiTokenSilent(instance,accounts,apiScopes)
        .catch(() => obtainApiTokenRedirect(instance, accounts, apiScopes))
}

// Silently acquires an access token which is then attached to a request for Longhurst Connector
const obtainLonghurstApiTokenSilent = async (instance: IPublicClientApplication, accounts: AccountInfo[], apiScopes: string[]): Promise<string> => {
    const silentRequest: SilentRequest = {
        scopes: apiScopes,
        account: accounts[0],
    }
    // Silently acquires an access token which is then attached to a request for Longhurst Connector
    return instance.acquireTokenSilent(silentRequest)
        .then((response) => Promise.resolve(response.accessToken))
        .catch((err) => Promise.reject(err))
}

export type UseApiTokenService = () => Promise<string>

export const useApiTokenService = (allowRedirect: boolean = false): UseApiTokenService => {
    const {instance, accounts} = useMsal()
    if(allowRedirect){
        return () => obtainLonghurstApiToken(instance, accounts, getAuthConfig().longhurstScopes)
    } else {
        return async () => obtainLonghurstApiTokenSilent(instance, accounts, getAuthConfig().longhurstScopes)
    }
}

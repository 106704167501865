import {useLonghurstService} from '../../service/LonghurstConnectorServiceHook'
import React, {useContext, useState} from 'react'
import Button from 'react-bootstrap/Button'
import {FormState} from './FollowOnOrderFormReducer'
import {ApiAuthorisationContext} from '../ApiAuthorisationContext'
import {OkModal} from '../common/OkModal'
import {
    buildWorkOrderRequest,
    getParamSimulateResponseBody,
    getParamSimulateStatusCode,
    getParamSimulateStatusCodeText
} from './CreateLonghurstOrderFormCommon'

function maybeOverrideResponseHeaders(response: Response) {
    let ok = response.ok
    let statusCode = response.status
    let statusText = response.statusText
    let simulateStatusCode: string | null = getParamSimulateStatusCode()
    let simulateStatusCodeText: string | null = getParamSimulateStatusCodeText()
    if (simulateStatusCode !== null) {
        console.info(`simulating server status code: ${simulateStatusCode}`)
        statusCode = Number(simulateStatusCode)
        ok = !isNaN(statusCode) && statusCode >= 200 && statusCode < 300
    }
    if (simulateStatusCodeText !== null) {
        console.info(`simulating server status code: ${simulateStatusCodeText}`)
        statusText = simulateStatusCodeText
    }
    return {ok, statusCode, statusText}
}

function maybeOverrideResponseBody(actualBody: string) {
    let simulateResponseBody: string | null = getParamSimulateResponseBody()
    if (simulateResponseBody) {
        console.info(`simulating server response body: ${simulateResponseBody}`)
        return simulateResponseBody
    } else {
        return actualBody
    }
}

function formatIfPossible(message: string) {
    // attempt to format as json
    try {
        return JSON.stringify(JSON.parse(message), null, 2)
    } catch (e) {
        // assume not json
    }
    return message
}

const COPY_TECHNICAL_ISSUE = 'Your request couldn\'t be completed due to a technical issue with the system.'
const COPY_CONTACT_SUPPORT = 'For more information, please contact technical support team.'
const COPY_TRY_REFRESH = 'You could try closing this tab and re-opening the Portal.'
/**
 * This component helps to encapsulate all the functionality required when submitting the form.
 * @param props
 * @constructor
 */
export const CreateLonghurstOrderSubmitButton = (props: {
    form: FormState,
    disabled: boolean,
    validateForm: () => boolean
    resetForm: () => void
}) => {
    // Services
    const apiAuthorisationCtx = useContext(ApiAuthorisationContext)
    const longhurstService = useLonghurstService()

    // Button State
    const [waitingForSubmissionResponse, setWaitingForSubmissionResponse] = useState(false)

    //MessageBox State
    const [showOkModal, setShowOkModal] = useState(false)
    const [message, setMessage] = useState<string>('')
    const [messageTitle, setMessageTitle] = useState<string>('')

    const displaySuccess = () => {
        setMessageTitle('Delivered')
        setMessage('Order details have been submitted successfully for processing,\nplease check the downstream systems manually')
        setShowOkModal(true)
    }

    const displayError = (title: string, errorMessages: string[]) => {
        console.info(`Displaying message box: ${title}: ${errorMessages}`)
        setMessageTitle(title)
        let message: string = ''
        errorMessages.forEach(msg => {
            message = message + formatIfPossible(msg) + '\n\n'
        })
        setMessage(message)
        setShowOkModal(true)
    }

    const displayServerError = (responseBody: string, status: number, statusText?: string) => {
        askUserToContactTechnicalSupport('Server Error', responseBody, status, statusText)
    }

    const displayUnknownError = (responseBody: string, status: number, statusText?: string) => {
        askUserToContactTechnicalSupport('Unexpected Error', responseBody, status, statusText)
    }

    const askUserToContactTechnicalSupport = (titlePrefix: string,
                                              responseBody: string,
                                              status: number,
                                              statusText?: string) => {
        let title = `${titlePrefix}: ${status} ${statusText}`
        let message = `${COPY_TECHNICAL_ISSUE}\n${COPY_CONTACT_SUPPORT}`
        displayError(title, [message, responseBody])
    }

    const displayAuthenticationError = (responseBody: string, status: number, statusText?: string) => {
        let title = `Authentication Error: ${status} ${statusText}`
        let message = `You're not allowed to make this request.\n${COPY_TRY_REFRESH}\n${COPY_CONTACT_SUPPORT}`
        displayError(title, [message, responseBody])
    }

    const onClickHandler = async () => {
        console.debug('CreateLonghurstOrderSubmitButton.onClickHandler called')
        if (props.validateForm()) {
            return submitNewWorkOrder()
        }
    }

    const submitNewWorkOrder = async () => {
        setWaitingForSubmissionResponse(true)
        if (apiAuthorisationCtx.longhurstAuthorised) {
            longhurstService.create(buildWorkOrderRequest(props.form))
                .then(response => {
                    let {ok, statusCode, statusText} = maybeOverrideResponseHeaders(response)
                    response.text().then(text => {
                        text = maybeOverrideResponseBody(text)
                        if (ok) {
                            displaySuccess()
                            props.resetForm()
                        } else if (statusCode === 400) { // validation error probably
                            displayError('User Data Error', [text])
                        } else if (statusCode === 401 || statusCode === 403) { // unauthorised || forbidden
                            displayAuthenticationError(text, statusCode, statusText)
                        } else if (`${statusCode}`.startsWith('4')) { // other 4xx errors
                            displayError(`Error Occurred: ${statusCode} ${statusText}`, [COPY_TECHNICAL_ISSUE, COPY_CONTACT_SUPPORT, text])
                        } else if (`${statusCode}`.startsWith('5')) { // 5xx errors
                            displayServerError(text, statusCode, statusText)
                        } else {
                            displayUnknownError(text, statusCode, statusText)
                        }
                    }).catch(error => {
                        displayError(`Error reading response: ${statusCode} ${statusText}`, [JSON.stringify(error)])
                    })
                }).catch(error => {
                // Failure of browser perhaps
                displayError('Portal Error:', [JSON.stringify(error)])
            })
        } else {
            displayError('Authentication Token Error:', [COPY_TRY_REFRESH])
        }
    }

    return <>
        <OkModal
            name={messageTitle}
            show={showOkModal}
            message={message}
            onClose={() => {
                setWaitingForSubmissionResponse(false)
                setShowOkModal(false)
            }}/>
        <Button variant='primary'
                onClick={onClickHandler}
                disabled={props.disabled || waitingForSubmissionResponse}>
            Create a Follow-On Order
        </Button>
    </>
}

export default CreateLonghurstOrderSubmitButton

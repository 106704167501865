import React, {useContext, useEffect, useReducer, useState} from 'react'
import Button from 'react-bootstrap/Button'
import {Alert, CloseButton, Col, Container, Form, Row} from 'react-bootstrap'
import {OkModal} from '../common/OkModal'
import {ApiAuthorisationContext} from '../ApiAuthorisationContext'
import {IWTextField} from '../common/IWTextField'
import {IWSelectField} from '../common/IWSelectField'
import {IWDynamicListTextField} from '../common/IWDynamicListTextField'
import {followOnFormReducer, FormState} from './FollowOnOrderFormReducer'
import {useApiTokenService} from '../../service/ObtainApiTokenServiceHook'
import {CreateLonghurstOrderSubmitButton} from './CreateLonghurstOrderSubmitButton'
import {buildWorkOrderRequest, isLocalValidationDisabled, isPageDebugEnabled} from './CreateLonghurstOrderFormCommon'
import {useMsal} from '@azure/msal-react'
import {getCurrentDateTime} from '../../util/DateTime'
import {IPublicClientApplication} from '@azure/msal-browser/dist/app/IPublicClientApplication'
import {useLonghurstService} from '../../service/LonghurstConnectorServiceHook'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import {IWAsyncTypeahead, Option} from '../common/IWAsyncTypeahead'
import moment from 'moment'

function emptyFormWithDefaults(msalInstance: IPublicClientApplication) {
    return {
        existingOrderRef: '',
        shortDescription: '',
        description: '',
        priority: '',
        contract: '',
        location: '',
        propertyUprn: '',
        contactTitle: '',
        contactFirstName: '',
        contactLastName: '',
        contactPhone: '',
        accessDetails: '',
        needTypes: [],
        disabilities: [],
        sors: [{
            rowId: getCurrentDateTime().valueOf(),
            code: '',
            quantity: '1',
        }],
        reportedBy: msalInstance.getAllAccounts()[0].username,
        existingOrderRaisedDate: getCurrentDateTime().format('yyyy-MM-DDTHH:mm'),
    }
}

/**
 * Displays an order form which can be used to send new orders to the longhurst portal
 */
export const CreateLonghurstOrderForm = () => {
    const {instance: msalInstance} = useMsal()
    const apiAuthorisationCtx = useContext(ApiAuthorisationContext)
    const getLonghurstToken = useApiTokenService(true)
    const longhurstService = useLonghurstService()
    const [showOkModal, setShowOkModal] = useState(false)
    const [isFormValid, setIsFormValid] = useState(false)
    const [displayFormErrors, setDisplayFormErrors] = useState(false)
    const [errors, setErrors] = useState<string[]>([])
    const [message, setMessage] = useState<string>('')
    const [messageTitle, setMessageTitle] = useState<string>('')
    const [hasFetchedOrder, setHasFetchedOrder] = useState<boolean>(false)
    const [sors, setSors] = useState<Option[]>([])

    const [form, dispatch] = useReducer(followOnFormReducer, emptyFormWithDefaults(msalInstance))

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)

    useEffect(() => {
        dispatch({type: 'restore-session'})
        // we only ever want to run this on first load hence the empty dependency list
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!apiAuthorisationCtx.longhurstAuthorised) {
            getLonghurstToken()
                .then(() => {
                    apiAuthorisationCtx.setLonghurstAuthorised(true)
                })
                .catch(err => {
                    apiAuthorisationCtx.setLonghurstAuthorised(false)
                    displayMessage('Error Obtaining token', err)
                })
        }
    }, [apiAuthorisationCtx, getLonghurstToken])


    /**
     * Very simplistic validation method, if we were trying to do a better job and highlight error fields etc.
     * If so we would need a deeper implementation, probably changing the forms reducer to manage the validation
     * methods, validation states, touched states etc.
     *
     * For the moment as we have no time, I have been asked to deliver a minimal validation function ASAP.
     */
    const validateForm = (): boolean => {
        if (isLocalValidationDisabled()) {
            console.info('local validation disabled returning valid for the purposes of testing server validation')
            return true
        }
        console.info('validating form')
        let valid = true
        const errors: string[] = []
        if (form.existingOrderRef.trim() === '') {
            errors.push('existing order ref is a required field')
            valid = false
        }
        if (form.description.trim() === '') {
            errors.push('description is a required field')
            valid = false
        }
        if (form.priority.trim() === '') {
            errors.push('priority is a required field')
            valid = false
        }
        if (form.contract.trim() === '') {
            errors.push('contract is a required field')
            valid = false
        }
        if (form.propertyUprn.trim() === '') {
            errors.push('property UPRN is a required field')
            valid = false
        }
        if (form.contactPhone.trim() === '') {
            errors.push('contact phone is a required field')
            valid = false
        }
        if (form.existingOrderRaisedDate.trim() === '') {
            errors.push('existing order raised date is a required field')
            valid = false
        }
        form.needTypes.forEach((needType, idx) => {
            if (needType.trim() === '') {
                errors.push(`need type[${idx + 1}] is empty`)
                valid = false
            }
        })
        form.disabilities.forEach((disability, idx) => {
            if (disability.trim() === '') {
                errors.push(`disability[${idx + 1}] is empty`)
                valid = false
            }
        })
        if (form.sors.length < 1) {
            errors.push('an Order should have at least 1 SOR')
            valid = false
        }
        form.sors.forEach((sor, idx) => {
            if (sor.code.trim() === '') {
                errors.push(`SOR[${idx + 1}] code is invalid`)
                valid = false
            }
            if (sor.quantity.trim() === '') {
                errors.push(`SOR[${idx + 1}] quantity is empty`)
                valid = false
            }
        })
        setErrors(errors)
        setIsFormValid(valid)
        if (!valid) {
            setDisplayFormErrors(true)
            window.scrollTo(0, 0)
        }
        return valid
    }

    useEffect(() => {
        dispatch({type: 'update', field: 'reportedBy', value: msalInstance.getAllAccounts()[0].username})
    }, [msalInstance])

    useEffect(() => {
        let authorised = apiAuthorisationCtx.longhurstAuthorised
        setSubmitButtonDisabled(!authorised)
    }, [apiAuthorisationCtx])

    // Utility functions here to keep html clean rather than having them inline
    const setExistingOrderRef = (v: string) => dispatch({type: 'update', field: 'existingOrderRef', value: v})
    const setShortDescription = (v: string) => dispatch({type: 'update', field: 'shortDescription', value: v})
    const setDescription = (v: string) => dispatch({type: 'update', field: 'description', value: v})
    const setPriority = (v: string) => dispatch({type: 'update', field: 'priority', value: v})
    const setContract = (v: string) => dispatch({type: 'update', field: 'contract', value: v})
    const setLocation = (v: string) => dispatch({type: 'update', field: 'location', value: v})
    const setPropertyUprn = (v: string) => dispatch({type: 'update', field: 'propertyUprn', value: v})
    const setContactTitle = (v: string) => dispatch({type: 'update', field: 'contactTitle', value: v})
    const setContactFirstName = (v: string) => dispatch({type: 'update', field: 'contactFirstName', value: v})
    const setContactLastName = (v: string) => dispatch({type: 'update', field: 'contactLastName', value: v})
    const setContactPhone = (v: string) => dispatch({type: 'update', field: 'contactPhone', value: v})
    const setAccessDetails = (v: string) => dispatch({type: 'update', field: 'accessDetails', value: v})
    const setExistingOrderRaisedDate = (v: string) => dispatch({
        type: 'update',
        field: 'existingOrderRaisedDate',
        value: v
    })
    const updateDisabilities = (v: string, idx: number) => dispatch({
        type: 'update-string-array',
        field: 'disabilities',
        value: v,
        index: idx
    })
    const updateNeedTypes = (v: string, idx: number) => dispatch({
        type: 'update-string-array',
        field: 'needTypes',
        value: v,
        index: idx
    })

    const addNeedType = () => dispatch({
        type: 'add-string-entry',
        field: 'needTypes'
    })

    const deleteNeedType = (index: number) => dispatch({
        type: 'delete-from-array',
        field: 'needTypes',
        index,
    })

    const deleteDisability = (index: number) => dispatch({
        type: 'delete-from-array',
        field: 'disabilities',
        index,
    })

    const addDisability = () => {
        console.info('adding a disability')
        dispatch({
            type: 'add-string-entry',
            field: 'disabilities',
        })
    }

    const updateSorQuantity = (newValue: string, index: number) => dispatch({
        type: 'update-sor-quantity',
        quantity: newValue,
        index
    })

    const updateSorCode = (newValue: string, index: number) => dispatch({
        type: 'update-sor-code',
        code: newValue,
        index
    })
    const addSor = () => dispatch({
        type: 'add-sor',
    })
    const deleteSor = (index: number) => dispatch({
        type: 'delete-from-array',
        field: 'sors',
        index
    })

    const displayMessage = (title: string, message: string) => {
        setMessageTitle(title)
        setMessage(message)
        setShowOkModal(true)
    }

    const formState = (): FormState => {
        return {
            accessDetails: form.accessDetails,
            contactFirstName: form.contactFirstName,
            contactLastName: form.contactLastName,
            contactPhone: form.contactPhone,
            contactTitle: form.contactTitle,
            contract: form.contract,
            description: form.description,
            disabilities: form.disabilities,
            existingOrderRaisedDate: form.existingOrderRaisedDate,
            existingOrderRef: form.existingOrderRef,
            location: form.location,
            needTypes: form.needTypes,
            priority: form.priority,
            propertyUprn: form.propertyUprn,
            shortDescription: form.shortDescription,
            sors: form.sors,
            reportedBy: form.reportedBy,
        }
    }

    const getWorkOrderHandler = async () => {
        let orderRef = formState().existingOrderRef.trim()
        reset()
        setExistingOrderRef(orderRef)

        longhurstService.get(orderRef)
            .then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        setPropertyUprn(json.uprn)
                        setLocation(json.location)
                        setContactTitle(json.contactTitle)
                        setContactFirstName(json.contactFirstName)
                        setContactLastName(json.contactLastName)
                        setContactPhone(json.contactPhone)
                        setPriority(json.priority)
                        setContract(json.contract)
                        if (json.priority === 'Routine') setExistingOrderRaisedDate(moment(json.orderCreatedDate).format('yyyy-MM-DDTHH:mm'))
                        json.needTypes.forEach(updateNeedTypes)
                        json.disabilities.forEach(updateDisabilities)
                        setHasFetchedOrder(true)
                    })
                } else if (response.status === 404) {
                    displayMessage('Existing order reference not found',
                        'Please check you have entered the full order reference of an existing Longhurst order.\nE.g. CAS-123456-A1B2C3.1.')
                } else {
                    displayMessage(`Unexpected Error: ${response.status} ${response.statusText}`,
                        'Your request couldn\'t be completed due to a technical issue with the system.\nFor more information, please contact technical support team.')
                }
            })
    }

    const reset = () => {
        // we hide form errors until we call validate
        setDisplayFormErrors(false)
        dispatch({
            type: 'reset-form',
            newFormState: emptyFormWithDefaults(msalInstance)
        })
        setHasFetchedOrder(false)
    }

    return (
        <>
            <OkModal
                name={messageTitle}
                show={showOkModal}
                message={message}
                onClose={() => {
                    setShowOkModal(false)
                }}/>
            <Alert variant={'primary'}>
                <Alert.Heading>
                    Longhurst - Follow-on Order Form
                </Alert.Heading>
                Create orders which follow-on from existing orders.
            </Alert>
            {!isFormValid && displayFormErrors && <Alert variant={'danger'}>
                <Alert.Heading>
                    Form is not valid
                </Alert.Heading>
                {
                    errors.map((error, idx) => {
                        return <ul key={`error-${idx}`}>{error}</ul>
                    })
                }
            </Alert>}
            <Form>
                <Form.Group>
                    <IWTextField name='existingOrderRef' value={form.existingOrderRef} label='Existing Order Ref'
                                 onChange={setExistingOrderRef}/>

                    <Button variant={'outline-primary'} onClick={getWorkOrderHandler} disabled={formState().existingOrderRef.trim().length === 0}>Get Order Details</Button>
                    <hr/>

                    <IWTextField name='shortDescription' value={form.shortDescription} label='Short Description'
                                 onChange={setShortDescription}/>
                    <IWTextField name='description' value={form.description} label='Description'
                                 onChange={setDescription}/>

                    <IWSelectField className='form-control' name='priority' value={form.priority} label='Priority'
                                   onChange={setPriority}>
                        <option></option>
                        <option value='Emergency'>Emergency (4 Hours)</option>
                        <option value='Urgent'>Urgent (7 Days)</option>
                        <option value='Routine'>Routine (28 Days)</option>
                    </IWSelectField>

                    <IWSelectField className='form-control' name='contract' value={form.contract} label='Contract'
                                   onChange={setContract}>
                        <option></option>
                        <option value='PPP'>PPP</option>
                        <option value='Exclusion'>Exclusion</option>
                    </IWSelectField>

                    <IWTextField name='location' value={form.location} label='Location' onChange={setLocation}/>
                    <IWTextField name='propertyUprn' value={form.propertyUprn} label='Property UPRN'
                                 onChange={setPropertyUprn}/>

                    <Row>
                        <Form.Text>Contact Name</Form.Text>
                        <Form.Group as={Col} xs={3}>
                            <IWSelectField name='contactTitle' value={form.contactTitle} label='Title'
                                           onChange={setContactTitle}>
                                <option></option>
                                <option value='Ms'>Ms</option>
                                <option value='Miss'>Miss</option>
                                <option value='Mrs'>Mrs</option>
                                <option value='Mr'>Mr</option>
                            </IWSelectField>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <IWTextField name='contactFirstName' value={form.contactFirstName} label='First Name'
                                         onChange={setContactFirstName}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <IWTextField name='contactLastName' value={form.contactLastName} label='Last Name'
                                         onChange={setContactLastName}/>
                        </Form.Group>
                    </Row>

                    <IWTextField name='contactPhone' value={form.contactPhone} label='Contact Phone'
                                 onChange={setContactPhone}/>
                    <IWTextField name='accessDetails' value={form.accessDetails} label='Access Details'
                                 onChange={setAccessDetails}/>
                    <hr/>
                    <IWDynamicListTextField onChange={(newValue, index) => updateNeedTypes(newValue, index)}
                                            onCreate={addNeedType}
                                            onDelete={deleteNeedType}
                                            name='needTypes'
                                            label='Need Type'
                                            title='Need Types'
                                            addButtonLabel='Add a Need Type'
                                            readOnly={hasFetchedOrder}
                                            values={form.needTypes}/>
                    <hr/>
                    <IWDynamicListTextField onChange={(newValue, index) => updateDisabilities(newValue, index)}
                                            onCreate={addDisability}
                                            onDelete={deleteDisability}
                                            name='disabilities'
                                            label='Disabilities'
                                            title='Disabilities'
                                            addButtonLabel='Add a Disability'
                                            readOnly={hasFetchedOrder}
                                            values={form.disabilities}/>
                    <hr/>

                    <IWTextField name='existingOrderRaisedDate' value={form.existingOrderRaisedDate}
                                 label='Existing Order Raised Date'
                                 onChange={setExistingOrderRaisedDate}
                                 type='datetime-local'/>
                    <Form.Text>For emergency and urgent orders use the current date and time</Form.Text>
                </Form.Group>
                <hr/>

                <Container>
                    <Form.Group>
                        <h5>SORs</h5>
                        {
                            form.sors.map((sor, idx) => {
                                return <Row key={`sor_${sor.rowId}`}>
                                    <Col md={9}>
                                        <IWAsyncTypeahead
                                            id={`sorCode${idx}`}
                                            label='Description'
                                            controlId={`sorCode${idx}`}
                                            options={sors}
                                            onChange={(option: Option) => {
                                                option ? updateSorCode(option.id, idx) : updateSorCode('', idx)
                                            }}
                                            onSearch={(query: string) => {
                                                longhurstService.findSors(query)
                                                    .then(response => {
                                                        if (response.ok) {
                                                            response.json().then(setSors)
                                                        }
                                                    })
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <IWTextField name={`sorQuantity${idx}`}
                                                     label='Quantity'
                                                     value={sor.quantity}
                                                     type='number'
                                                     onChange={(newValue) => updateSorQuantity(newValue, idx)}/>
                                    </Col>
                                    <Col>
                                        {form.sors.length > 1 && // minimum of 1 SOR must be provided
                                            <CloseButton onClick={() => deleteSor(idx)}/>
                                        }
                                    </Col>
                                </Row>
                            })
                        }
                        <Row>
                            <Col>
                                <Button variant={'outline-primary'} onClick={addSor}>Add a SOR</Button>
                            </Col>
                            <Col></Col>
                        </Row>
                    </Form.Group>
                </Container>
                <hr/>
                <Form.Group>
                    <CreateLonghurstOrderSubmitButton form={formState()}
                                                      disabled={submitButtonDisabled}
                                                      validateForm={validateForm}
                                                      resetForm={reset}
                    />
                </Form.Group>

                {isPageDebugEnabled() && <>
                    <hr/>
                    <h5>Other debug params available on this page are</h5>
                    <ul>
                        <li>
                            <strong>disableLocalValidation</strong> (disableLocalValidation=true will not validate the
                            form locally and instead use server side validation)
                        </li>
                        <li><strong>simulateStatusCode</strong> (simulateStatusCode=500 will simulate httpStatus code500
                            when handling responses from LH connector)
                        </li>
                        <li><strong>simulateStatusCodeText</strong> (simulateStatusText=SERVER_ERROR will simulate
                            httpStatusText when handling responses from LH connector)
                        </li>
                        <li><strong>simulateResponseBody</strong> (simulateStatusText=lots%20of%20text will simulate the
                            response body when handling responses from LH connector)
                        </li>
                    </ul>
                    <hr/>
                    <Form.Group>
                        <h5>This is the json which will be sent to the server</h5>
                        <Form.Control
                            type='text'
                            as='textarea'
                            rows={30}
                            draggable='true'
                            disabled={true}
                            value={JSON.stringify(buildWorkOrderRequest(formState()), null, ' ')}
                        />
                    </Form.Group>
                </>
                }
            </Form>
        </>)
}

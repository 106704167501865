import React from 'react'
import {useMsal} from '@azure/msal-react'
import Button from 'react-bootstrap/Button'
import {IPublicClientApplication} from '@azure/msal-browser/dist/app/IPublicClientApplication'
import {LOGIN_REQUEST_SCOPES} from '../../config/AuthConstants'

function handleLogin(instance: IPublicClientApplication) {
    const request = {
        ...LOGIN_REQUEST_SCOPES,
    }
    instance.loginRedirect(request).catch(e => {
        console.error(e)
    })
}

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = () => {
    const {instance} = useMsal()

    return (
        <Button size='lg'
                onClick={() => handleLogin(instance)}>
            Sign in
        </Button>
    )
}

import {RedirectRequest} from '@azure/msal-browser/dist/request/RedirectRequest'
import {Configuration} from '@azure/msal-browser/dist/config/Configuration'

/**
 * The Azure Tenant ID is a Global Unique Identifier (GUID) for your Microsoft 365 Tenant. It's also referred to as the
 * Office 365 Tenant ID. The ID is used to identify your tenant, and it's not your organization name or domain name.
 */
export const TENANT_ID = '94a416ee-70db-41c3-8432-73a1c1e3e31f'

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const LOGIN_REQUEST_SCOPES: RedirectRequest = {
    scopes: ['User.Read']
}

export const LONGHURST_CONNECTOR_REQUEST_SCOPES_TEST: Array<string> = ['b366227e-fc77-48dc-aa00-bcef70e4c14a/.default']
export const LONGHURST_CONNECTOR_REQUEST_SCOPES_STAGING: Array<string> = ['2180e67e-a8f5-4470-920e-9b756a73f4c2/.default']
export const LONGHURST_CONNECTOR_REQUEST_SCOPES_PRODUCTION: Array<string> = ['3d720b11-fad9-4b50-8717-6e745bfcaa65/.default']

/**
 * Generic configuration for authentication and authorisation via the Msal library provided by microsoft.
 */
const msalConfig: Configuration = {
    auth: {
        // 'Application (client) ID' of app registration in Azure portal - this value is a GUID
        // Will need to be made dynamic for different environments if we want to restrict access to different envs
        clientId: 'Please provide',
        // Full directory URL, in the form of https://login.microsoftonline.com/<tenant-id>
        authority: 'https://login.microsoftonline.com/' + TENANT_ID,
        // Full redirect URL, in form of http://localhost:3000
        // Will also need to dynamically change according to the environment we are running in and these are based on
        // branch name
        redirectUri: 'PleaseProvide',
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
}

/**
 * configuration purely for developers when running the app locally.
 */
export const localMsalConfig: Configuration = {
    ...msalConfig,
    auth: {
        ...msalConfig.auth,
        clientId: 'cf30f809-521d-4c91-aa83-965ea3f58025',
        redirectUri: 'http://localhost:3000',
    },
}

/**
 * used in the test and other preview environments other than any named environments such as staging.
 */
export const testMsalConfig: Configuration = {
    ...msalConfig,
    auth: {
        ...msalConfig.auth,
        clientId: 'cf30f809-521d-4c91-aa83-965ea3f58025',
        redirectUri: 'https://delightful-grass-06ab28703-staging.westeurope.2.azurestaticapps.net/',
    },
}

/**
 * configuration for the staging environemt.
 */
export const stagingMsalConfig: Configuration = {
    ...msalConfig,
    auth: {
        ...msalConfig.auth,
        clientId: '42592a09-edc3-4f52-995a-33aa35e09ac8',
        redirectUri: 'https://delightful-grass-06ab28703-staging.westeurope.2.azurestaticapps.net/',
    },
}

/**
 * configuration for production
 */
export const productionMsalConfig: Configuration = {
    ...msalConfig,
    auth: {
        ...msalConfig.auth,
        clientId: '1f3af131-8123-4a77-b786-40e9ed93299d',
        redirectUri: 'https://portal.ianwilliams.co.uk',
    },
}

export type MaybeError<V, E> = Value<V, E> | Er<V, E>;

export class Value<V, E> {
    readonly value: V

    constructor(value: V) {
        this.value = value
    }

    isOk(): this is Value<V, E> {
        return true
    }

    isErr(): this is Er<V, E> {
        return false
    }
}

export class Er<V, E> {
    readonly error: E

    constructor(error: E) {
        this.error = error
    }

    isOk(): this is Value<V, E> {
        return false
    }

    isErr(): this is Er<V, E> {
        return true
    }
}

export const value = <V, E>(v: V): MaybeError<V, E> => {
    return new Value(v)
}

export const err = <V, E>(e: E): MaybeError<V, E> => {
    return new Er<V, E>(e)
}
